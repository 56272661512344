import React from "react";

import { Container, Card, Button, Row, Col } from "react-bootstrap";

import Video from "../Modals/Video";

import proof329 from "../../Images/proof329.png";

import * as courses from "../../courseKeys";
export default function Core(props) {
  return (
    <div className="App-body">
      <div className="core">
        <Container>
          <h2>Core</h2>
          <p class="description">
            All CSUMB BS CS students must pass a core set of classes with a
            grade of C- or better (but a better grade is strongly preferred as a
            target).
          </p>
          <Card id={courses.cst300}>
            <a name={courses.cst300}>
              <h4>CST 300 Major ProSeminar</h4>
            </a>
            <Card.Body>
              <h5>Course Objective</h5>
              <p class="description">
                Helps students identify and articulate personal, professional,
                and social goals.Provides an integrated overview of the computer
                science and communication design majors and their requirements.
                Students develop a plan for their learning goals. Students learn
                writing, presentation, research and critical-thinking skills
                within the diversified fields of information technology and
                communication design. Students learn how to analyze, predict,
                and articulate trends in the academic, public service,
              </p>
              <Container>
                <Row>
                  <Col>
                    <Button href="https://csumb-files-bucket.s3.amazonaws.com/industryanalysis.pdf">
                      Industry Analysis Paper
                    </Button>
                  </Col>
                  <Col>
                    <Button href="https://csumb-files-bucket.s3.amazonaws.com/ethicspaper.pdf">
                      Ethics Paper
                    </Button>
                  </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                  <Col>
                    <Video
                      video={
                        "https://www.youtube-nocookie.com/embed/kL5FMevxXnI"
                      }
                      title={"POSIXOtters - Web Development Intro for Kids"}
                    />
                  </Col>
                  <Col>
                    <Video
                      video={
                        "https://www.youtube-nocookie.com/embed/0vrGY-BXIfs"
                      }
                      title={"POSIXOtters - Web Development"}
                    />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <br />
          <Card id={courses.cst329}>
            <a name={courses.cst329}>
              <h4>CST 329 Reasoning with Logic</h4>
            </a>
            <Card.Body>
              <p class="description">
                In this course students learn to develop skill in using logic to
                describe and assess arguments. Students will learn to write
                formulas in propositional and first-order logic, and more
                importantly, to write and check proofs. Students will also learn
                to write formulas in three-valued and modal logic, know how to
                choose an appropriate logic, and understand the limitations of
                logic in capturing human reasoning.
              </p>
              <img src={proof329} width="780" height="747" />
            </Card.Body>
          </Card>
          <br />
          <Card id={courses.cst334}>
            <a name={courses.cst334}>
              <h4>CST 334 Operating Systems</h4>
            </a>
            <Card.Body>
              <p class="description">
                Students in this course will learn about the use and design of
                modern operating systems, focusing on Linux. On the “use” side,
                students will learn the Linux command line, to write shell
                scripts, and to build programs with GNU utilities like awk, sed,
                and make. On the “design” side, students will develop a deep
                understanding of process management, memory management, file
                systems, and concurrency, and how they apply to modern
                technologies like virtualization and cloud computing.
              </p>
              <br />
              <Container>
                <Row>
                  <Col>
                    <Video
                      video={
                        "https://www.youtube-nocookie.com/embed/1Nmm-lZm-Fs"
                      }
                      title={"CST334 Presentation"}
                    />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <br />
          <Card id={courses.cst338}>
            <a name={courses.cst338}>
              <h4>CST 338 Software Design</h4>
            </a>
            <Card.Body>
              <p class="description">
                Provides students with the fundamental concepts to develop
                large-scale software, focusing on the object-oriented
                programming techniques. Coverage includes the introduction to
                Java programming language, object-oriented programming, software
                life cycle and development processes, requirements analysis, and
                graphical user interface development.
              </p>
              <Container>
                <Row>
                  <Col>
                    <Button href="https://csumb-files-bucket.s3.amazonaws.com/338Final.zip">
                      CST338 Final
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <br />
          <Card id={courses.cst370}>
            <a name={courses.cst370}>
              <h4>CST 370 Design and Analysis of Algorithms</h4>
            </a>
            <Card.Body>
              <p class="description">
                Students learn important data structures in computer science and
                acquire fundamental algorithm design techniques to get the
                efficient solutions to several computing problems from various
                disciplines. Topics include the analysis of algorithm
                efficiency, hash, heap, graph, tree, sorting and searching,
                brute force, divide-and-conquer, decrease-and-conquer,
                transform-and-conquer, dynamic programming, and greedy
                programming.
              </p>
              <Container>
                <Row>
                  <Col>
                    <Button href="../../Files/370_hw6_1.cpp">
                      Sample HW 1
                    </Button>
                  </Col>
                  <Col>
                    <Button href="../../Files/370_hw6_2.cpp">
                      Sample HW 2
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <br />
          <br />
          <p class="description">
            The following classes are doubled up. This is done for a single
            eight-week period in the program, and both courses are taken at the
            same time.
          </p>
          <Card id={courses.cst328}>
            <a name={courses.cst328}>
              <h4>CST 328 Digital Art and Design</h4>
            </a>
            <Card.Body>
              <p class="description">
                A survey course that combines the study of technology, design
                and aesthetic principles as they apply to twenty-first century
                visual media. Students learn how fundamental concepts of art and
                design apply to a broad range of commercial, expressive and
                entertainment forms. Students also study the democratization of
                the creative process through digital media, using current
                technology to produce sophisticated and expressive examples of
                personal digital creativity.
              </p>
              <br />
              <Container>
                <Row>
                  <Col>
                    <Video
                      video={
                        "https://www.youtube-nocookie.com/embed/w3-MZohd9oA"
                      }
                      title={"CST328 Final Video"}
                    />
                  </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                  <Col>
                    <Button href="https://csumb-files-bucket.s3.amazonaws.com/328Final.zip">
                      CST328 Final Project Download
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <br />
          <Card id={courses.cst462S}>
            <a name={courses.cst462S}>
              <h4>CST 462S Race, Gender, and Class in the Digital World</h4>
            </a>
            <Card.Body>
              <p class="description">
                Provides students with key knowledge of race, gender, class and
                social justice especially in relation to technology in today’s
                digital world. Students challenge the barriers of expertise,
                gender, race, class, and location that restrict wider access to
                and understanding of the production and usage of new
                technologies. Students will engage in a practical experience in
                the community via their service placements, which will provide
                depth and context for considering questions of justice,
                equality, social responsibilities and the complexities of
                technology and its societal impact. The course uses scenario
                based approach combining presentations, discussions, and
                reflections to allow students explore the relationship between
                critical reflection and action on the topics mentioned above.{" "}
              </p>
              <Container>
                <Row>
                  <Col>
                    <Button href="https://csumb-files-bucket.s3.amazonaws.com/researchpaper.pdf">
                      Industry Analysis Paper
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <br />
          <br />
          <p class="description">
            The following class stands on its own at the end of the program and
            is the culmination of everything else from both the core and the
            concentration.
          </p>
          <Card id={courses.cst499}>
            <a name={courses.cst499}>
              <h4>CST 499 Computer Science Capstone</h4>
            </a>
            <Card.Body>
              <p class="description">
                Students will work on a project in large groups (up to 5
                students in each group), developing requirements specification,
                a solution plan followed by design and implementation of the
                solution. The problem statement for the projects will be
                selected by the faculty. Faculty will also play the role of a
                project manager directing the schedule and deliverables for
                these projects.
              </p>
              <Container>
                <Row>
                  <Col>
                    <Button href="https://github.com/ASMach/CST499Capstone">
                      CST499 Source Code
                    </Button>
                  </Col>
                  <Col>
                    <Button href="https://csumb-files-bucket.s3.amazonaws.com/CST499Capstone-macOS.zip">
                      macOS App Package (x64 + ARM)
                    </Button>
                  </Col>
                  <Col>
                    <Button href="https://csumb-files-bucket.s3.amazonaws.com/CST499Capstone-Windows.zip">
                      Windows App Package (x64)
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <br />
        </Container>
      </div>
    </div>
  );
}
