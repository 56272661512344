import React from 'react';

import {Navbar, Nav, Container} from 'react-bootstrap';

export default function Footer () {
    return (
        <Navbar expand="sm" fixed="bottom" bg="light">
            <Container>
                <Nav.Link href="https://csumb.edu/csonline" target="_blank">
                    CSUMB Online Bachelor's Completion Program
                </Nav.Link>
            </Container>
        </Navbar>
    );
}