export default class CourseSelector {
  static instance = CourseSelector.instance || new CourseSelector();
  course = "";

  setCourse(course) {
    this.course = course;
  }

  getCourse() {
    return this.course;
  }
}
