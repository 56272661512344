import React from "react";

import { Container, Card, Button, Link, Row, Col } from "react-bootstrap";

import Video from "../Modals/Video";

import DownloadLink from "react-download-link";

const projects = {
  sitar: "pocketSitar",
  predictiveSeek: "predictiveSeek",
};

export default function Projects(props) {
  return (
    <div className="App-body">
      <div className="core">
        <Container>
          <h2>Projects</h2>
          <p class="description">
            In addition to class projects, I have done a selection of
            independent projects over the years. Further information on them can
            be seen below.
          </p>
          <Card id={projects.sitar}>
            <a name={projects.sitar}>
              <h4>Pocket Sitar</h4>
            </a>
            <Card.Body>
              <h5>Details</h5>
              <p class="description">
                First ever mobile digital sitar, released in 2010 on the iOS App
                Store. This app peaked at #39 in the music category within a few
                days of its release and was available until the middle of 2012.
              </p>
              <Container>
                <Row>
                  <Col>
                    <a href="https://worldmusiccentral.org/2010/02/02/is-that-a-sitar-in-your-pocket-iphone-sitar-application-released/">
                      World Music Central Article
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <a href="https://www.npr.org/sections/alltechconsidered/2010/02/could_you_be_a_sitar_hero.html">
                      NPR Article
                    </a>
                  </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                  <Col>
                    <Video
                      video={
                        "https://www.youtube-nocookie.com/embed/xfT0HF1Pbxk"
                      }
                      title={"Pocket Sitar Demo Video"}
                    />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <br />
          <Card id={projects.tabla}>
            <a name={projects.tabla}>
              <h4>Pocket Tabla</h4>
            </a>
            <Card.Body>
              <h5>Details</h5>
              <p class="description">
                It was my first iOS app, released in the summer of 2009. This
                app was available until the summer of 2015, but I have since
                open-sourced it on GitHub for the wider community.
              </p>
              <Container>
                <Row>
                  <Col>
                    <a href="https://github.com/ASMach/FingerTabla-1.1-Multifile">
                      Source Code on GitHub
                    </a>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <br />
          <Card id={projects.sitar}>
            <a name={projects.sitar}>
              <h4>Pengin Jetpack Adventure!</h4>
            </a>
            <Card.Body>
              <h5>Details</h5>
              <p class="description">
                Fly a penguin through floating hoops using a jetpack, and
                collect gems too! This was my first Unity game, released in 2012
                on the OS X App Store. It is no longer available there, but
                Windows and macOS builds can be downloaded here for free.
              </p>
              <Container>
                <Row>
                  <Col>
                    <DownloadLink
                      label="Download Windows Build"
                      filename="../Files/JetpackPenguinWindows.zip"
                      exportFile={() =>
                        Promise.resolve(
                          "Penguin Jetpack Adventure! (Windows)..."
                        )
                      }
                    />
                  </Col>
                  <Col>
                    <DownloadLink
                      label="Download macOS (x86-64) Build"
                      filename="https://asmach-portfolio.s3.us-west-1.amazonaws.com/PenguinJetpackAdventureMac.app.zip"
                      exportFile={() =>
                        Promise.resolve(
                          "Penguin Jetpack Adventure! (macOS x86-64)..."
                        )
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <br />
          <Card id={projects.tabla}>
            <a name={projects.tabla}>
              <h4>Predictive Character Seeking</h4>
            </a>
            <Card.Body>
              <h5>Details</h5>
              <p class="description">
                During the pandemic, I built this system to allow NPCs in video
                games to predict the movement of a player character based on
                prior seen positions when the player goes out of an NPC's line
                of sight. It can be downloaded from the Unity Asset Store.
              </p>
              <Container>
                <Row>
                  <Col>
                    <a href="https://assetstore.unity.com/packages/tools/ai/predictive-character-seeking-185419">
                      Asset Store Page
                    </a>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
}
