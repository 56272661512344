import React from "react";

import Contact from "./Modals/Contact";
import logo from "../Images/logo.svg";

import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import CourseSelector from "../CourseSelector";

import * as courses from "../courseKeys";

export default function Navigation(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div id="navbar">
      <Navbar collapseOnSelect expand="lg" fixed="top" bg="light">
        <Navbar.Brand href="https://www.csumb.edu" target="_blank">
          <img
            src={logo}
            width="60"
            height="60"
            className="d-inline-block align-top"
            alt="CSUMB Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">About</Nav.Link>
            <Nav.Link href="/projects">Projects</Nav.Link>
            <NavDropdown
              title="BS CS Core Classes"
              id="responsive-nav-dropdown"
              href="/core"
            >
              <NavDropdown.Item
                href={"/core#" + courses.cst300}
                onClick={CourseSelector.instance.setCourse(courses.cst300)}
              >
                CST 300
              </NavDropdown.Item>
              <NavDropdown.Item
                href={"/core#" + courses.cst329}
                onClick={CourseSelector.instance.setCourse(courses.cst329)}
              >
                CST 329
              </NavDropdown.Item>
              <NavDropdown.Item
                href={"/core#" + courses.cst334}
                onClick={CourseSelector.instance.setCourse(courses.cst334)}
              >
                CST 334
              </NavDropdown.Item>
              <NavDropdown.Item
                href={"/core#" + courses.cst338}
                onClick={CourseSelector.instance.setCourse(courses.cst338)}
              >
                CST 338
              </NavDropdown.Item>
              <NavDropdown.Item
                href={"/core#" + courses.cst370}
                onClick={CourseSelector.instance.setCourse(courses.cst370)}
              >
                CST 370
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                href={"/core#" + courses.cst328}
                onClick={CourseSelector.instance.setCourse(courses.cst328)}
              >
                CST 328
              </NavDropdown.Item>
              <NavDropdown.Item
                href={"/core#" + courses.cst462S}
                onClick={CourseSelector.instance.setCourse(courses.cst462S)}
              >
                CST 462S
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                href={"/core#" + courses.cst499}
                onClick={CourseSelector.instance.setCourse(courses.cst499)}
              >
                CST 499
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="BCS CS Concentration"
              id="responsive-nav-dropdown"
              href="/concentration"
            >
              <NavDropdown.Item
                href={"/concentration#" + courses.cst311}
                onClick={CourseSelector.instance.setCourse(courses.cst311)}
              >
                CST 311
              </NavDropdown.Item>
              <NavDropdown.Item
                href={"/concentration#" + courses.cst336}
                onClick={CourseSelector.instance.setCourse(courses.cst336)}
              >
                CST 336
              </NavDropdown.Item>
              <NavDropdown.Item
                href={"/concentration#" + courses.cst363}
                onClick={CourseSelector.instance.setCourse(courses.cst363)}
              >
                CST 363
              </NavDropdown.Item>
              <NavDropdown.Item
                href={"/concentration#" + courses.cst383}
                onClick={CourseSelector.instance.setCourse(courses.cst383)}
              >
                CST 383
              </NavDropdown.Item>
              <NavDropdown.Item
                href={"/concentration#" + courses.cst438}
                onClick={CourseSelector.instance.setCourse(courses.cst438)}
              >
                CST 438
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav.Link onClick={() => setModalShow(true)}>Contact</Nav.Link>
        </Navbar.Collapse>
      </Navbar>

      <Contact show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}
