import React from "react";

import { Container, Card } from "react-bootstrap";

import * as courses from "../../courseKeys";

export default function LowerDivision(props) {
  return (
    <div className="App-body">
      <div className="lowerDivision">
        <Container>
          <h2>Lower Division</h2>
          <p class="description">
            As an online degree completion program,{" "}
            <a href="https://csumb.edu/csonline" target="_blank">
              CSUMB Online
            </a>{" "}
            is only available to transfer students from community colleges.
            There are a number of classes that must be taken at a community
            college in order to complete the program, and some are required
            before transfer as a bare minimum.
          </p>
          <Card id={courses.cst231}>
            <a name={courses.cst231}>
              <h4>CST 231 Problem Solving and Programming</h4>
            </a>
            <Card.Body>
              <p class="description">
                This is an introductory programming course to develop
                problem-solving techniques for numerical and non-numerical
                problems from various disciplines. Students will design the
                solution to each problem and implement it in the C++ programming
                language. Coverage includes introduction to computer system, C++
                basics, flow control, functions, I/O streams, arrays, strings,
                and classes.
              </p>
              <br />
              <h5>
                <a href="www.sbcc.edu">SBCC</a> CS 140
              </h5>
            </Card.Body>
          </Card>
          <br />
          <Card id={courses.cst237}>
            <a name={courses.cst237}>
              <h4>CST 237 Computer Architecture</h4>
            </a>
            <Card.Body>
              <p class="description">
                This course introduces computer architectures and computer
                systems management. Includes data representation, memory,
                registers, and internals of common computers and system
                interfaces. Knowledge of C/C++ programming helpful but not
                mandatory.
              </p>
              <br />
              <h5>
                <a href="www.sbcc.edu">SBCC</a> CS 107
              </h5>
            </Card.Body>
          </Card>
          <br />
          <Card id={courses.cst238}>
            <a name={courses.cst238}>
              <h4>CST 238 Introduction to Data Structures</h4>
            </a>
            <Card.Body>
              <p class="description">
                Provides students with intermediate-level programming skills to
                make it possible for them to write clear, efficient, and
                high-quality code. Students learn basic data structures such as
                array, list, stack, queue, and tree. They also acquire several
                basic algorithms in programming such as searching, sorting, and
                recursion. Furthermore, students learn in-depth knowledge of C++
                programming language such as classes, pointers, and dynamic
                memory management.
              </p>
              <br />
              <h5>
                <a href="www.sbcc.edu">SBCC</a> CS 145 (now SBCC CS 107)
              </h5>
            </Card.Body>
          </Card>
          <br />
          <Card id={courses.math150}>
            <a name={courses.math150}>
              <h4>MATH 150 Calculus 1</h4>
            </a>
            <Card.Body>
              <p class="description">
                Includes limits, continuity, derivatives including trigonometric
                functions, chain rule, curve sketching, extremum problems,
                implicit differentiation, related rates, Mean Value Theorem,
                logarithmic and trigonometric functions, introduction to
                integration, fundamental theorem of calculus, substitution, and
                applications.
              </p>
              <br />
              <h5>
                <a href="www.oxnardcollege.edu">Oxnard College</a> MATH R120
              </h5>
            </Card.Body>
          </Card>
          <br />
          <Card id={courses.math151}>
            <a name={courses.math151}>
              <h4>MATH 151 Calculus 2</h4>
            </a>
            <Card.Body>
              <p class="description">
                Includes the calculus of exponential and logarithmic functions,
                trigonometric and inverse trigonometric functions, techniques of
                integration, separable differential equations, Taylor
                polynomials, L’Hôpital’s rule, improper integrals, series, and
                introduction to partial derivatives.
              </p>
              <br />
              <h5>
                <a href="www.coastline.edu">Coastline Community College</a> MATH
                C185
              </h5>
            </Card.Body>
          </Card>
          <br />
          <Card id={courses.math170}>
            <a name={courses.math170}>
              <h4>MATH 170 Discrete Math</h4>
            </a>
            <Card.Body>
              <p class="description">
                Includes sets and sequences, elementary logic, relations,
                induction, counting principles, discrete probability, Boolean
                algebra, logic networks, matrices, graph theory, and trees.
                Applies these topics to real life and branches of science,
                particularly computer science.
              </p>
              <br />
              <h5>
                <a href="http://extension.harvard.edu">
                  Harvard Extension School
                </a>{" "}
                CSCI E-20
              </h5>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
}
