import React, { Component } from "react";

import { Modal, Button } from "react-bootstrap";

import { Download } from "react-bootstrap-icons";

export default class FramePopover extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
    show: false,
  };

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    const { show } = this.state;

    const { resource, file, title } = this.props;

    return (
      <div>
        <Button variant="primary" onClick={this.handleShow}>
          {title}
        </Button>
        <Modal
          show={show}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe src={resource} width="100%" height="600px">
              This browser does not support HTML frames. You may download this
              file.
            </iframe>
            <a href={file} target="_blank">
              <Download />
            </a>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
