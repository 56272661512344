import React from "react";
import "../App.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Core, About, Concentration, LowerDivision, Projects } from "./Pages";

import Navigation from "./Navigation";
import Footer from "./Footer";

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Switch>
          <Route path="/" exact component={() => <About />} />
          <Route path="/core" exact component={() => <Core />} />
          <Route
            path="/concentration"
            exact
            component={() => <Concentration />}
          />
          <Route
            path="/lowerdivision"
            exact
            component={() => <LowerDivision />}
          />
          <Route path="/projects" exact component={() => <Projects />} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
