import React from 'react';

import {Modal, Button, Row, Col} from 'react-bootstrap'

import { SocialIcon } from 'react-social-icons';

export default function Contact(props) {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Contact & Connect
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col>
                    <SocialIcon url="https://www.linkedin.com/in/alonzo-machiraju/"/>
                </Col>
                <Col>
                    <SocialIcon url="http://www.github.com/asmach"/>
                </Col>
                <Col>
                    <SocialIcon url="mailto:amachiraju@csumb.edu"/>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }