import React from "react";

import { Container } from "react-bootstrap";

export default function About(props) {
  return (
    <div className="App-body">
      <div className="about">
        <Container>
          <h2>Hello</h2>
          <p class="description">
            I am Alonzo Machiraju, an independent{" "}
            <a href="https://en.wikipedia.org/wiki/Adobe_Flash" target="_blank">
              Flash
            </a>{" "}
            (remember those days?) turned{" "}
            <a
              href="http://developer.apple.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              iOS
            </a>{" "}
            turned{" "}
            <a
              href="http://www.unity3d.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              Unity
            </a>{" "}
            turned{" "}
            <a
              href="http://www.reactjs.org"
              rel="noopener noreferrer"
              target="_blank"
            >
              React
            </a>{" "}
            developer now making a pivot into AI and Machine Learning. I have
            been writing code since childhood and still work in everything
            except Flash.
          </p>
          <p class="description">
            I have worked{" "}
            <a href="http://www.huddl.ai" target="_blank">
              Huddl.ai
            </a>
            , an intelligent meeting startup, as well as{" "}
            <a href="http://www.octi.tv" target="_blank">
              Octi, Inc
            </a>
            , an augmented reality social networking startup. I am also
            highly-interested in virtual reality, augmented reality. I am
            currently a computer graphics engineer at{" "}
            <a href="http://metacareers.com">Meta</a> through{" "}
            <a href="http://www.aditiconsulting.com">Aditi Consulting</a>.
          </p>
        </Container>
      </div>
    </div>
  );
}
