import React from "react";

import { Container, Card, Button, Row, Col } from "react-bootstrap";

import FramePopover from "../Modals/FramePopover";

import * as courses from "../../courseKeys";

import DownloadLink from "react-download-link";

export default function Concentration(props) {
  return (
    <div className="App-body">
      <div className="concentration">
        <Container>
          <h2>Concentration</h2>
          <p class="description">
            While CSUMB offers{" "}
            <a href="https://catalog.csumb.edu/preview_program.php?catoid=2&poid=256#concentrations">
              four different undergraduate concentrations
            </a>
            , only the{" "}
            <a href="https://catalog.csumb.edu/preview_program.php?catoid=2&poid=259">
              Software Engineering Concentration
            </a>{" "}
            is fulfilled in the online program.
          </p>
          <Card id={courses.cst311}>
            <a name={courses.cst311}>
              <h4>CST 311 Introduction to Computer Networking</h4>
            </a>
            <Card.Body>
              <p class="description">
                Survey of Telecomm and Data Comm Technology Fundamentals, Local
                Area Network, Wide Area Network, Internet and internetworking
                protocols including TCP/IP, network security and performance,
                emerging industry trends such as voice over the network and high
                speed networking. Designed as a foundation for students who wish
                to pursue more advanced network studies including certificate
                programs. Includes hands-on networking labs that incorporate
                Cisco CCNA lab components.
              </p>
            </Card.Body>
          </Card>
          <br />
          <Card id={courses.cst336}>
            <a name={courses.cst336}>
              <h4>CST 336 Internet Programming</h4>
            </a>
            <Card.Body>
              <p class="description">
                Provides students with dynamic web application development
                skills, focusing on the integration of server-side programming,
                database connectivity, and client-side scripting. Coverage
                includes the Internet architecture, responsive design, RESTful
                web services, and Web APIs.
              </p>
              <Container>
                <Row>
                  <Col>
                    <a
                      href="https://replit.com/@AlonzoMachiraju/WorthlessGivingTechnologies"
                      target="_blank"
                    >
                      Final Project on Replit
                    </a>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <br />
          <Card id={courses.cst363}>
            <a name={courses.cst363}>
              <h4>CST 363 Database Management</h4>
            </a>
            <Card.Body>
              <p class="description">
                This course provides balanced coverage of database use and
                design, focusing on relational databases. Students will learn to
                design relational schemas, write SQL queries, access a DB
                programmatically,and perform database administration. Students
                will gain a working knowledge of the algorithms and data
                structures used in query evaluation and transaction processing.
                Students will also learn to apply newer database technologies
                such as XML, NoSQL, and Hadoop.
              </p>
              <Container>
                <Row>
                  <Col>
                    <DownloadLink
                      label="Download CST363 Setup"
                      filename="../Files/363.zip"
                      exportFile={() => Promise.resolve("363 Setup...")}
                    />
                  </Col>
                  <Col>
                    <DownloadLink
                      label="Download CST363 Demo"
                      filename="../Files/363demo.zip"
                      exportFile={() => Promise.resolve("363 Demo...")}
                    />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <br />
          <Card id={courses.cst383}>
            <a name={courses.cst383}>
              <h4>CST 383 Introduction to Data Science</h4>
            </a>
            <Card.Body>
              <p class="description">
                In data science, data analysis and machine learning techniques
                are applied to visualize data, understand trends, and make
                predictions. In this course students will learn how to obtain
                data, preprocess it, apply machine learning methods, and
                visualize the results. A student who completes the course will
                have enough theoretical knowledge, and enough skill with modern
                statistical programming languages and their libraries, to define
                and perform complete data science projects.
              </p>
              <Container>
                <Row>
                  <Col>
                    <FramePopover
                      title={"383 Final Notebook"}
                      resource={
                        "https://csumb-files-bucket.s3.amazonaws.com/383final.html"
                      }
                      file={
                        "https://csumb-files-bucket.s3.amazonaws.com/383final.ipynb"
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <br />
          <Card id={courses.cst438}>
            <a name={courses.cst438}>
              <h4>CST 438 Software Engineering</h4>
            </a>
            <Card.Body>
              <p class="description">
                Prepares students for large-scale software development using
                software engineering principles and techniques. Coverage
                includes software process, requirements analysis and
                specification, software design, implementation, testing, and
                project management. Students are expected to work in teams to
                carry out a realistic software project.
              </p>
              <br />
              <Container>
                <Row>
                  <Col>
                    <Button href="https://github.com/ASMach/CST438-Gradebook-frontend-amachiraju">
                      CST438 Frontend
                    </Button>
                  </Col>
                  <Col>
                    <Button href="https://github.com/ASMach/CST438-Gradebook-backend-amachiraju">
                      CST438 Backend
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <br />
        </Container>
      </div>
    </div>
  );
}
